import React from 'react';

import { SocrataIcon } from 'common/components/SocrataIcon';

const AllowedUsersList = ({ userList, removeUser }) => {
  const listItems = userList.map(user => {
    return (
      <li key={user.id} className="allowed-user-list-item">
        <span className="allowed-user-row">
          <button className="remove-user-button" onClick={() => removeUser(user)}>
            <SocrataIcon name="cross2" />
          </button>
          {user.email}
        </span>
      </li>
    );
  });

  return <ul>{listItems}</ul>;
};

export default AllowedUsersList;
