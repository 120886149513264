import React from 'react';

import Button from 'common/components/Button';
import { showInfoToastNow } from 'common/components/ToastNotification/Toastmaster';
import * as gatesApi from 'common/core/config_gates/provisions';

const DeleteGateButton = ({ name, refreshData }) => {
  const onClick = async () => {
    try {
      if (!confirm('Delete gate?')) { return; }

      await gatesApi.deleteGate(name);
      showInfoToastNow('Successfully deleted gate.');
      await refreshData();
    } catch (error) {
      console.error(error);
    }
  };

  const buttonProps = {
    onClick,
    className: 'delete-gate',
    variant: 'error'
  };

  return <Button {...buttonProps}>Delete</Button>;
};

export default DeleteGateButton;
