import React from 'react';

const ConfigGatesExplanation = () => {
  return (
    <h6 className="config-gates-explanation">
      <div>Gates can be used to prevent or slow down updates to feature flags and modules.</div>
      <div>You can create a gate with varying levels of restriction to either:</div>
      <ol>
        <li>warn users who are trying to change the gated flag/module, or</li>
        <li>restrict the ability to change the gated flag/module to a select list of allowed superadmin users.</li>
      </ol>
      <div>Over time, we may extend this functionality to apply to other configuration types as well.</div>
    </h6>
  );
};

export default ConfigGatesExplanation;
