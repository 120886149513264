import React from 'react';
import { connect } from 'react-redux';

import ResultsTable from 'common/components/ResultsTable';
import Button from 'common/components/Button';
import CachedAutocomplete from 'common/components/CachedAutocomplete';
import * as gateApi from 'common/core/config_gates/provisions';
import * as frontendApi from 'common/frontend/internal';
import UsersApi from 'common/users-api';
import utcToString from 'common/utcToString';

import * as actions from '../actions';
import DeleteGateButton from './DeleteGateButton';
import ConfigGatesExplanation from './ConfigGatesExplanation';
import GateModal, { MODES } from './GateModal';

class ConfigGates extends React.Component {
  async componentDidMount() {
    this.fetchAllGates();
    this.fetchSuperAdmins();
    this.fetchFeatureFlags();
    this.fetchModules();
  }

  fetchAllGates = async () => {
    try {
      const response = await gateApi.getAllGates();
      const data = toTableData(response);
      this.props.setData(data);
      this.props.setDataCached(data);
    } catch (error) {
      console.error(`fetchAllGates error: ${error}`);
    }
  };

  fetchSuperAdmins = async () => {
    try {
      const superAdmins = await UsersApi.getSuperAdmins();
      this.props.setSuperAdmins(superAdmins);
    } catch (error) {
      console.error(`fetchSuperAdmins error: ${error}`);
    }
  };

  fetchFeatureFlags = async () => {
    try {
      const featureFlags = await frontendApi.featureFlagList();
      this.props.setFeatureFlags(featureFlags);
    } catch (error) {
      console.error(`fetchFeatureFlags error: ${error}`);
    }
  };

  fetchModules = async () => {
    try {
      const modules = await frontendApi.moduleList();
      const moduleNames = modules.map((module) => module.name);
      this.props.setModules(moduleNames);
    } catch (error) {
      console.error(`fetchModules error: ${error}`);
    }
  };

  onSuccess = () => {
    this.props.hideModal();
    return this.fetchAllGates();
  };

  // Render functions for cells of each row.
  renderType = gateType => ({
    flag: 'Feature Flag',
    module: 'Module'
  })[gateType];

  renderCreatedAt = createdAt => <div>{utcToString(createdAt)}</div>;

  renderUsers = selectedUserIds => {
    const { superAdmins } = this.props;
    const users = superAdmins.filter(user => selectedUserIds.has(user.oid));
    return users.length > 0 ?
      <ul>{users.map(u => <li key={u.id}>{u.email}</li>)}</ul> :
      <div>Any</div>;
  };

  renderActions = (index, gateObj) => {
    const editButtonProps = {
      onClick: () => this.props.showModal(MODES.EDIT, gateObj),
      className: 'edit-gate',
      variant: 'default'
    };

    const name = `${gateObj.gateType}:${gateObj.gateName}`;

    return (
      <span>
        <Button {...editButtonProps}>Edit Gate</Button>
        <DeleteGateButton name={name}
                          refreshData={this.fetchAllGates} />
      </span>
    );
  };

  render() {
    const {
      data,
      dataCached,
      setData,
      modalFormData,
      modalHidden,
      modalMode,
      hideModal,
      showModal,
      superAdmins,
      featureFlags,
      modules
    } = this.props;

    const modalProps = {
      modalFormData,
      modalHidden,
      modalMode,
      superAdmins,
      featureFlags,
      modules,
      onSuccess: this.onSuccess,
      onDismiss: hideModal
    };

    const createButtonProps = {
      onClick: () => showModal(MODES.CREATE, {}),
      className: 'create-gate',
      variant: 'primary'
    };

    const searchBarProps = {
      dataCached,
      onQuerySubmit: setData,
      onClearSearch: () => setData(dataCached), // resets table
      placeholder: 'Find a Gate',
      className: 'find-a-gate',
      searchKeys: ['gateName']
    };

    return (
      <div>
        <GateModal {...modalProps} />
        <h2 className="config-gates-title">Config Gates</h2>
        <ConfigGatesExplanation />
        <Button {...createButtonProps}>Create Gate</Button>
        <CachedAutocomplete {...searchBarProps} />
        <div className="gate-table-container">
          <ResultsTable data={data}
                        noResultsMessage={''}
                        rowKey='index'>
            <ResultsTable.Column dataIndex='gateName'
                                 header='Gate Name' />
            <ResultsTable.Column dataIndex='gateType'
                                 header='Gate Type'>
              {this.renderType}
            </ResultsTable.Column>
            <ResultsTable.Column dataIndex='reason'
                                 header='Reason' />
            <ResultsTable.Column dataIndex='createdAt'
                                 header='Date Created'>
              {this.renderCreatedAt}
            </ResultsTable.Column>
            <ResultsTable.Column dataIndex='selectedUserIds'
                                 header='Allowed Users'
                                 dataClassName='allowed-users-cell'>
              {this.renderUsers}
            </ResultsTable.Column>
            <ResultsTable.Column dataIndex='index'
                                 header='Actions'
                                 dataClassName='actions-cell'>
              {this.renderActions}
            </ResultsTable.Column>
          </ResultsTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.data,
  dataCached: state.dataCached,
  modalMode: state.modalMode,
  modalHidden: state.modalHidden,
  modalFormData: state.modalFormData,
  superAdmins: state.superAdmins,
  featureFlags: state.featureFlags,
  modules: state.modules
});

const mapDispatchToProps = {
  setData: actions.setData,
  setDataCached: actions.setDataCached,
  setSuperAdmins: actions.setSuperAdmins,
  setFeatureFlags: actions.setFeatureFlags,
  setModules: actions.setModules,
  showModal: actions.showModal,
  hideModal: () => actions.hideModal
};

// Helpers
const toTableData = response => {
  return response.map((record, index) => {
    const [gateType, gateName] = record.provisionLookup.split(':');
    const selectedUserIds = record.allowedUsers ?
      record.allowedUsers.map(u => u.oid) : [];

    return {
      ...record,
      index,
      selectedUserIds: new Set(selectedUserIds),
      gateName,
      gateType
    };
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigGates);
