import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

import ConfigGates from './components/ConfigGates';
import configGatesReducer from './reducer';
import './config-gates.scss';

ReactDOM.render(
  <Provider store={createStore(configGatesReducer)}>
    <ConfigGates />
  </Provider>,
  document.querySelector('#config-gates-index')
);
