import * as actions from './actions';

// Shape of the store
const initialState = {
  data: [],
  dataCached: [], // for search/filter
  modalFormData: {},
  modalHidden: true,
  modalMode: '',
  superAdmins: [],
  featureFlags: [],
  modules: [],
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_DATA:
      return { ...state, data: action.data };
    case actions.SET_DATA_CACHED:
      return { ...state, dataCached: action.dataCached };
    case actions.UPDATE_MODAL_FORM_DATA:
      return {
        ...state,
        modalFormData: {
          ...state.modalFormData,
          ...action.update
        }
      };
    case actions.SHOW_MODAL:
      return {
        ...state,
        modalHidden: false,
        modalMode: action.modalMode,
        modalFormData: action.modalFormData
      };
    case actions.HIDE_MODAL:
      return { ...state, modalHidden: true };
    case actions.SET_SUPERADMINS:
      return { ...state, superAdmins: action.superAdmins };
    case actions.SET_FEATURE_FLAGS:
      return { ...state, featureFlags: action.featureFlags };
    case actions.SET_MODULES:
      return { ...state, modules: action.modules };
    case actions.SET_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
};
