import React from 'react';

import { DropdownWithLabel } from 'common/components/Forms';

export const GATE_TYPES = {
  FLAG: 'flag',
  MODULE: 'module'
};

const GateTypeDropdown = props => {
  const {
    disabled,
    handleSelection,
    value
  } = props;


  const gateTitles = {
    [GATE_TYPES.FLAG]: 'Feature Flag',
    [GATE_TYPES.MODULE]: 'Module',
  };

  const options = Object
    .values(GATE_TYPES)
    .map(val => ({ value: val, title: gateTitles[val] }));

  const dropdownProps = {
    disabled,
    options,
    value,
    handleSelection,
    className: 'gate-type-dropdown'
  };

  return <DropdownWithLabel {...dropdownProps} />;
};

export default GateTypeDropdown;
