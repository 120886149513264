import React from 'react';

import GateSubmitForm from './GateSubmitForm';

import Modal, { ModalContent, ModalHeader } from 'common/components/Modal';

export const MODES = {
  EDIT: 'Edit',
  CREATE: 'Create'
};

const GateModal = props => {
  const {
    modalFormData,
    modalHidden,
    modalMode,
    onDismiss,
    onSuccess,
    superAdmins
  } = props;

  if (modalHidden) {
    return null;
  }

  const title = `${modalMode} Gate`;

  const modalProps = {
    onDismiss,
    className: 'gate-modal',
    overlay: true
  };

  const submitFormProps = {
    modalFormData,
    modalMode,
    onSuccess,
    superAdmins
  };

  return (
    <Modal {...modalProps}>
      <ModalHeader title={title} onDismiss={onDismiss}/>
      <ModalContent className="gate-modal-content">
        <GateSubmitForm {...submitFormProps} />
      </ModalContent>
    </Modal>
  );
};

export default GateModal;
