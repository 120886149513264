import React from 'react';
import { connect } from 'react-redux';

import { SocrataIcon } from 'common/components/SocrataIcon';
import CachedAutocomplete from 'common/components/CachedAutocomplete';

import * as actions from '../actions';
import { GATE_TYPES } from './GateTypeDropdown';

const KEY = 'gateName';

class GatePicker extends React.Component {
  // Overly-complex destructuring, but: grab the first object in
  // array with key of <KEY> and assign value to <gateName>.
  // focusFirstResult prop ensures list is length of 1
  onQuerySubmit = ([{ [KEY]: gateName }]) => {
    this.props.updateModalFormData({ gateName });
  };

  renderSelectedGate = () => {
    const unsetButton = !this.props.editMode && (
      <button className="unset-gate-name-button" onClick={() => this.props.updateModalFormData({ gateName: '' })}>
        <SocrataIcon name="cross2" />
      </button>
    );

    return (
      <div className="gate-name">
        <strong>{this.props.gateName}</strong>
        {unsetButton}
      </div>
    );
  };

  renderGateSearchBar = () => {
    const {
      gateType,
      featureFlags,
      modules,
    } = this.props;

    if (!Object.values(GATE_TYPES).includes(gateType)) {
      console.warn(`unrecognized gateType: ${gateType}`);
    }

    const gateList = gateType === GATE_TYPES.MODULE ? modules : featureFlags;
    // map Strings to Objects to conform with CachedAutocomplete
    // should match shape of params for onQuerySubmit
    const dataCached = gateList.map(gateName => ({ [KEY]: gateName }));

    const gateSearchBarProps = {
      dataCached,
      onQuerySubmit: this.onQuerySubmit,
      searchKeys: [KEY],
      placeholder: 'Find a Gate',
      className: 'find-a-gate',
      focusFirstResult: true, // ensures length of 1 for onQuerySubmit
      onSelectSetSelectionAsQuery: false,
    };

    return (
      <CachedAutocomplete {...gateSearchBarProps} />
    );
  };

  render() {
    const renderGateName = this.props.gateName !== '' ?
      this.renderSelectedGate :
      this.renderGateSearchBar;

    return (
      <div className="gate-name-wrapper">
        <label>Gate Name</label>
        {renderGateName()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  gateType: state.modalFormData.gateType || 'flag',
  gateName: state.modalFormData.gateName || '',
  featureFlags: state.featureFlags,
  modules: state.modules,
});

const mapDispatchToProps = {
  updateModalFormData: actions.updateModalFormData
};

export default connect(mapStateToProps, mapDispatchToProps)(GatePicker);
