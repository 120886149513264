import React from 'react';
import { connect } from 'react-redux';

import { TextArea } from 'common/components/Forms';
import Button from 'common/components/Button';
import CachedAutocomplete from 'common/components/CachedAutocomplete';
import * as gatesApi from 'common/core/config_gates/provisions';
import { showInfoToastNow } from 'common/components/ToastNotification/Toastmaster';

import * as actions from '../actions';
import { MODES } from './GateModal';

import AllowedUsersList from './AllowedUsersList';
import GatePicker from './GatePicker';
import GateTypeDropdown from './GateTypeDropdown';

class GateSubmitForm extends React.Component {
  handleTextChange = key => event => {
    const { setError, updateModalFormData } = this.props;
    updateModalFormData({ [key]: event.target.value });
    setError(null);
  };

  handleGateTypeChange = ({ value }) => {
    const { setError, updateModalFormData } = this.props;
    updateModalFormData({ gateType: value, gateName: '' });
    setError(null);
  };

  handleAddAllowedUsers = usersToAdd => {
    const { selectedUserIds, updateModalFormData } = this.props;
    updateModalFormData({
      selectedUserIds: new Set([...selectedUserIds, ...usersToAdd.map(u => u.oid)])
    });
  };

  handleRemoveAllowedUser = userToRemove => {
    const { selectedUserIds, updateModalFormData} = this.props;
    selectedUserIds.delete(userToRemove.oid);
    updateModalFormData({ selectedUserIds });
  };

  handleSubmission = async () => {
    const {
      gateType,
      gateName,
      modalMode,
      onSuccess,
      reason,
      selectedUserIds,
      setError
    } = this.props;

    const provisionLookup = [gateType, gateName].join(':');
    const submissionHandler = modalMode === MODES.CREATE ?
      this.createGate :
      this.updateGate;

    try {
      await submissionHandler({ provisionLookup, reason, selectedUserIds });
      onSuccess();
    } catch (error) {
      console.error(error);
      const errResponse = await error.response.json();
      setError(errResponse.message);
    }
  };

  createGate = async ({ provisionLookup, reason, selectedUserIds }) => {
    await gatesApi.createGate({ provisionLookup, reason });
    if (selectedUserIds.size !== 0) {
      await gatesApi.addAllowedUsers(provisionLookup, Array.from(selectedUserIds));
    }
    showInfoToastNow('Successfully created gate!');
  };

  updateGate = async ({ provisionLookup, reason, selectedUserIds }) => {
    await gatesApi.updateGate({ provisionLookup, reason });
    await gatesApi.replaceAllowedUsers(provisionLookup, Array.from(selectedUserIds));
    showInfoToastNow('Successfully updated gate!');
  };

  renderError() {
    const { error } = this.props;
    if (!error) {
      return null;
    }

    return <div className="gate-modal-error">{error}</div>;
  }

  render() {
    const {
      gateType,
      modalMode,
      reason,
      superAdmins,
      selectedUserIds
    } = this.props;

    const editMode = modalMode === MODES.EDIT;

    const gateTypeProps = {
      disabled: editMode,
      handleSelection: this.handleGateTypeChange,
      value: gateType
    };

    const userSearchBarProps = {
      onQuerySubmit: this.handleAddAllowedUsers,
      dataCached: superAdmins.filter(user => !selectedUserIds.has(user.oid)),
      placeholder: 'Find a SuperAdmin',
      className: 'find-a-superadmin',
      searchKeys: ['displayName', 'email'],
      onSelectSetSelectionAsQuery: false
    };

    const userListProps = {
      userList: superAdmins.filter(user => selectedUserIds.has(user.oid)),
      removeUser: this.handleRemoveAllowedUser
    };

    return (
      <div className="gate-submit-form">
        <GateTypeDropdown {...gateTypeProps} />
        <GatePicker editMode={editMode}/>
        <TextArea label="Reason" onChange={this.handleTextChange('reason')} value={reason} />
        <CachedAutocomplete {...userSearchBarProps} />
        <AllowedUsersList {...userListProps} />
        {this.renderError()}
        <Button type="submit" className="gate-submit-button" variant="primary" onClick={this.handleSubmission}>
          Submit
        </Button>
      </div>
    );
  }
}

const selectedUserIdsFor = modalFormData => {
  const userIds = modalFormData.selectedUserIds || [];
  return new Set(userIds);
};

const mapStateToProps = state => ({
  modalMode: state.modalMode,
  modalFormData: state.modalFormData,
  superAdmins: state.superAdmins,
  gateType: state.modalFormData.gateType || 'flag',
  gateName: state.modalFormData.gateName || '',
  reason: state.modalFormData.reason || '',
  selectedUserIds: selectedUserIdsFor(state.modalFormData),
  error: state.error,
  featureFlags: state.featureFlags,
  modules: state.modules,
});

const mapDispatchToProps = {
  setError: actions.setError,
  updateModalFormData: actions.updateModalFormData
};

export default connect(mapStateToProps, mapDispatchToProps)(GateSubmitForm);
