export const SHOW_MODAL = 'SHOW_MODAL';
export const showModal = (modalMode, modalFormData) => ({
  type: SHOW_MODAL,
  modalMode,
  modalFormData
});

export const HIDE_MODAL = 'HIDE_MODAL';
export const hideModal = { type: HIDE_MODAL };

export const UPDATE_MODAL_FORM_DATA = 'UPDATE_MODAL_FORM_DATA';
export const updateModalFormData = update => ({ type: UPDATE_MODAL_FORM_DATA, update });

export const SET_DATA = 'SET_DATA';
export const setData = data => ({ type: SET_DATA, data });

export const SET_DATA_CACHED = 'SET_DATA_CACHED';
export const setDataCached = dataCached => ({ type: SET_DATA_CACHED, dataCached });

export const SET_SUPERADMINS = 'SET_SUPERADMINS';
export const setSuperAdmins = superAdmins => ({ type: SET_SUPERADMINS, superAdmins });

export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS';
export const setFeatureFlags = featureFlags => ({ type: SET_FEATURE_FLAGS, featureFlags });

export const SET_MODULES = 'SET_MODULES';
export const setModules = modules => ({ type: SET_MODULES, modules });

export const SET_ERROR = 'SET_ERROR';
export const setError = error => ({ type: SET_ERROR, error });
